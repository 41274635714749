import React from "react"
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"
import { Box, Flex } from "theme-ui"
import PlusSign from "../../../assets/svg/plus-sign.svg"
import MinusSign from "../../../assets/svg/minus-sign.svg"

const QuantityTickerContainer = styled(Flex)`
  display: ${(props) =>
    props.visible ? (props.sx?.display ? props.sx.display : "flex") : "none"};
  width: ${(props) => (props.sx?.width ? props.sx.width : "80px")};
  height: ${(props) => (props.sx?.height ? props.sx.height : "32px")};
  border: ${(props) =>
    props.withBorder
      ? props.sx?.border
        ? props.sx.border
        : props.theme.borders.grey
      : "1px solid transparent"};
  color: ${(props) => props.theme.colors.dark};

  ${(props) =>
    props.disabled &&
    `
      color: ${props.theme.colors.brownGrey};
     
    `}

  justify-content: space-between;
  align-items: center;
`
const Ticker = styled.button`
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  height: 16px;
  width: 16px;
  padding: 0;
  align-items: center;
  outline: none;

  ${(props) =>
    !props.disabled &&
    `
      cursor: pointer;
      &:hover {
        color: ${props.theme.colors.dark};
        background: ${props.theme.colors.brownGrey_20};        
      }
    `}

  ${(props) =>
    props.disabled &&
    `
      svg { 
        opacity: 0.5;
      }
    `}
`

const QuantityDisplay = styled(Box)`
  text-align: center;
  width: 18px;
`

const QuantityPicker = ({
  showSoldOut,
  quantity,
  onQuantityChanged,
  visible = true,
  disabled = false,
  withBorder = true,
  maxQuantity,
  minQuantity,
  sx,
  ...rest
}) => {
  const { t } = useTranslation()
  const handleDecrementQuantity = () => {
    if (minQuantity === null) {
      onQuantityChanged(quantity - 1)
    }
    if (onQuantityChanged && quantity !== minQuantity) {
      onQuantityChanged(quantity - 1)
    }
  }

  const handleIncrementQuantity = () => {
    if (maxQuantity === null) {
      onQuantityChanged(quantity + 1)
    }
    if (onQuantityChanged && quantity !== maxQuantity) {
      onQuantityChanged(quantity + 1)
    }
  }

  return showSoldOut ? (
    <QuantityTickerContainer
      visible={visible}
      disabled={disabled}
      withBorder={withBorder}
      sx={sx}
      {...rest}
    >
      <div />
      <QuantityDisplay sx={displaySx}>
        <i>{t("sold_out")}</i>
      </QuantityDisplay>
      <div />
    </QuantityTickerContainer>
  ) : (
    <QuantityTickerContainer
      visible={visible}
      disabled={disabled}
      withBorder={withBorder}
      sx={sx}
      {...rest}
    >
      <Flex sx={{ justifyContent: "center", flex: 1 }}>
        <Ticker
          disabled={
            disabled || (minQuantity !== null && quantity == minQuantity)
          }
          onClick={handleDecrementQuantity}
        >
          <MinusSign />
        </Ticker>
      </Flex>
      <QuantityDisplay className="display">{quantity || 0}</QuantityDisplay>
      <Flex sx={{ justifyContent: "center", flex: 1 }}>
        <Ticker
          disabled={
            disabled || (maxQuantity !== null && quantity == maxQuantity)
          }
          onClick={handleIncrementQuantity}
        >
          <PlusSign />
        </Ticker>
      </Flex>
    </QuantityTickerContainer>
  )
}

export default QuantityPicker

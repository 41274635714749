import styled from "@emotion/styled"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Flex, Text } from "theme-ui"
import CloseRing from "../../../assets/svg/close-ring.svg"
import OpenRing from "../../../assets/svg/open-ring.svg"
import Close from "../../../assets/svg/signifly-close.svg"
import Button from "../button"
import InputField from "../input-field"

const CodeInputContainer = styled(Flex)`
  .header {
    svg {
      cursor: pointer;
    }
  }
`

const InputContainer = styled(Flex)`
  display: ${(props) => (props.open ? "flex" : "none")};
`

const CodeInput = ({ type, discounts, onSubmit, onDelete, error, sx }) => {
  const [open, setOpen] = useState(false)
  const [input, setInput] = useState("")

  const { t } = useTranslation()

  const renderType = () => {
    switch (type) {
      case CodeTypes.GIFT_CARD:
        return t("gift_card_code_question")
      case CodeTypes.DISCOUNT:
        return t("promo_code_question")
      default:
        return ""
    }
  }

  const renderError = () => {
    if (!error) {
      return null
    }

    return typeof error === "string" ? error : t("enter_valid_code")
  }

  return (
    <CodeInputContainer sx={{ flexDirection: "column", ...sx }}>
      <Flex sx={{ fontSize: "14px" }} className="header">
        <Text sx={{ flex: "1" }}>{renderType()}</Text>
        {open ? (
          <CloseRing onClick={() => setOpen(false)} />
        ) : (
          <OpenRing onClick={() => setOpen(true)} />
        )}
      </Flex>
      <InputContainer open={open} mt={"8px"}>
        <InputField
          sx={{ flex: "1" }}
          errorSx={{ marginTop: "8px" }}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder={t("enter_code")}
          error={renderError()}
        />
        <Button
          sx={{ height: "40px" }}
          onClick={() => {
            onSubmit(input.trim()).then(() => {
              setInput("")
              setOpen(false)
            })
          }}
        >
          {t("submit")}
        </Button>
      </InputContainer>
      <TagGroup sx={{ marginTop: "4px" }}>
        {discounts &&
          discounts.map((discount) => (
            <TagLabel
              key={discount.code}
              value={discount.code}
              onDelete={() => onDelete(discount.code)}
            />
          ))}
      </TagGroup>
    </CodeInputContainer>
  )
}

export default CodeInput

export const CodeTypes = {
  GIFT_CARD: "GIFT_CARD",
  DISCOUNT: "DISCOUNT",
}

const Tag = styled(Flex)`
  background-color: ${(props) => props.theme.colors.brownGrey};
  color: ${(props) => props.theme.colors.white};
  padding: 8px 12px;
  margin: 4px 0;
  align-items: center;

  .close {
    cursor: pointer;
  }
`

export const TagLabel = ({ value, onDelete, ...rest }) => {
  return (
    <Tag {...rest}>
      <Text sx={{ fontSize: "13px" }} mr={"8px"}>
        {value}
      </Text>
      <Close className="close" onClick={onDelete} />
    </Tag>
  )
}

const TagContainer = styled(Flex)`
  flex-wrap: wrap;

  > ${Tag}:not(:last-of-type) {
    margin-right: 6px;
  }
`

export const TagGroup = ({ children, sx }) => {
  return <TagContainer sx={sx}>{children}</TagContainer>
}

import React, { useRef, useState, useEffect } from "react"
import styled from "@emotion/styled"
import { Flex } from "theme-ui"

import TooltipIcon from "../../../assets/svg/tooltip.svg"
import useClickOutside from "../../../hooks/useClickOutside"

const TooltipContainer = styled(Flex)`
  position: relative;
  padding-left: 3px;
  display: flex;
  width: fit-content;

  .tooltip-icon {
    font-size: 12px;
    margin: 0px 6px;
    cursor: pointer;
  }

  .tooltip-value {
    visibility: ${(props) => (props.active ? "visible" : "hidden")};
  }

  &:hover {
    .tooltip-value {
      visibility: visible;
    }
  }

  .text {
    display: flex;
    align-items: center;

    ${(props) =>
      props.iconFirst &&
      `
        flex-direction: row-reverse;
        
        .tooltip-icon {
          margin-left: 0px;
        }
    `}
  }
`

const TooltipValue = styled.div`
  position: absolute;
  display: block;
  white-space: normal;
  background: white;
  background-clip: padding-box;
  padding: 10px;
  border: 1px solid ${(props) => props.theme.colors.grey};
  width: 250px;
  height: auto;
  left: 0%;
  bottom: 0%;
  transform: translate3d(0%, -50%, 0px);
  line-height: 1.3;

  :before,
  :after {
    transform: translate3d(0px);
    content: "";
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: 100%;
    left: 0%;
    margin-left: 0px;
  }

  :before {
    border-top: 12px solid ${(props) => props.theme.colors.grey};
    margin-top: 0px;
  }

  :after {
    border-top: 12px solid white;
    margin-top: -1px;
    z-index: 1;
  }

  ${(props) =>
    props.iconFirst
      ? `
      :before, :after {
        transform: translate3d(0px, 0px, 0px);
      }
    `
      : `
    :before, :after {
      transform: translate3d(${props.width - 5}px, 0px, 0px);
    }
    `}

  ${(props) =>
    props.placement === TooltipPlacement.BOTTOM &&
    `
      transform: translate3d(0%, ${props.height + 5}px, 0px);

      :before, :after {
        top: -8%;
        border-top: none;
        margin-left: 0px;
      }

      :before {
        margin-bottom: 0px;
      }

      :after {
        border-bottom: 12px solid white;
        margin-bottom: -1px;
        z-index: 1;
      }
    
    `}

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    transform: translate3d(-5%, -50%, 0px);
    :before,
    :after {
      margin-left: 10px;
      transform: translate3d(0px);
    }

    ${(props) =>
      props.placement === TooltipPlacement.BOTTOM &&
      `
        transform: translate3d(-5%, ${props.height + 5}px, 0px);

      `}
  }
`

const Tooltip = ({
  text,
  popover,
  type = TooltipType.DEFAULT,
  sx,
  options = {
    withIcon: true,
    placement: TooltipPlacement.TOP,
    iconFirst: false,
  },
}) => {
  const tooltipRef = useRef()
  const tooltipValueRef = useRef()

  const [width, setWidth] = useState()
  const [height, setHeight] = useState()
  const [active, setActive] = useState(false)

  useClickOutside(tooltipRef, () => setActive(false), [setActive])

  const { withIcon, placement, iconFirst } = options

  useEffect(() => {
    if (tooltipRef?.current) {
      setWidth(tooltipRef.current.clientWidth)
    }
    if (tooltipValueRef?.current) {
      setHeight(tooltipValueRef.current.clientHeight)
    }
  }, [tooltipRef])

  const handleIcon = () => {
    switch (type) {
      case TooltipType.DEFAULT:
        return <TooltipIcon className="tooltip-icon" />
      default:
        return <TooltipIcon className="tooltip-icon" />
    }
  }

  return (
    <TooltipContainer
      hasTooltip={popover}
      placement={placement}
      iconFirst={iconFirst}
      onClick={() => setActive((active) => !active)}
      active={active}
      sx={sx}
    >
      <div className="text" ref={tooltipRef} id={`text-${text}`}>
        {text}
        {withIcon && handleIcon()}
      </div>

      <TooltipValue
        ref={tooltipValueRef}
        className="tooltip-value"
        placement={placement}
        iconFirst={iconFirst}
        width={width ? width - 20 : 40}
        height={height}
      >
        {popover}
      </TooltipValue>
    </TooltipContainer>
  )
}

export const TooltipType = {
  DEFAULT: "default",
  ATTENTION: "attention",
  INFORMATION: "information",
}

export const TooltipPlacement = {
  TOP: "TOP",
  BOTTOM: "BOTTOM",
}

export default Tooltip

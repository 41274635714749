import React from "react"
import { Flex } from "theme-ui"
import styled from "@emotion/styled"

import PaymentOptions from "../payment-options"

const FooterContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
`

const PaymentOptionsContainer = styled(Flex)`
  font-size: 16px;
`

const CartFooter = ({ cart, pl, pr, sx }) => {
  return (
    <FooterContainer pl={pl} pr={pr} sx={sx}>
      <PaymentOptionsContainer
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PaymentOptions
          short
          options={cart.region?.payment_providers.map(({ id }) => id) || []}
          margin="1rem 0"
        />
      </PaymentOptionsContainer>
    </FooterContainer>
  )
}

export default CartFooter

import React from "react"
import styled from "@emotion/styled"

import KlarnaLogo from "../../../assets/pay-icons/klarna.svg"
import GooglePay from "../../../assets/pay-icons/googlepay.svg"
import Mastercard from "../../../assets/pay-icons/mastercard.svg"
import Paypal from "../../../assets/pay-icons/paypal.svg"
import VISA from "../../../assets/pay-icons/visa.svg"
import JCB from "../../../assets/pay-icons/jcb.svg"
import ApplePay from "../../../assets/pay-icons/apple.svg"
import Amex from "../../../assets/pay-icons/amex.svg"
import Giropay from "../../../assets/pay-icons/giropay.svg"
import Ideal from "../../../assets/pay-icons/ideal.svg"

// import Mobilepay from "../../../assets/pay-icons/mobilepay.svg"
// import Maestro from "../../assets/icons/Maestro.svg"

const PaymentIcon = styled.img`
  height: 20px;
  margin-bottom: 5px;
  &:not(:last-of-type) {
    margin-right: 5px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.short ? "row" : "column")};
  align-items: ${(props) => (props.short ? "center" : "start")};
  margin: ${(props) => props.margin || ".5rem 0"};

  justify-content: ${(props) => (props.short ? "space-between" : "start")};
`

const PaymentMethods = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const PaymentOptions = ({ options, short, margin }) => {
  return (
    <Container short={short} margin={margin}>
      <PaymentMethods short={short}>
        {options.includes("stripe") && <PaymentIcon src={GooglePay} />}
        {options.includes("stripe") && <PaymentIcon src={ApplePay} />}
        {options.includes("paypal") && <PaymentIcon src={Paypal} />}
        {/* {options.includes("stripe") && <PaymentIcon src={Maestro} />} */}
        {/* {options.includes("mobilepay-adyen") && <PaymentIcon src={Mobilepay} />} */}
        {options.includes("klarna") && <PaymentIcon src={KlarnaLogo} />}
        {options.includes("stripe") && <PaymentIcon src={VISA} />}
        {options.includes("stripe") && <PaymentIcon src={Amex} />}
        {options.includes("stripe") && <PaymentIcon src={Mastercard} />}
        {options.includes("stripe") && <PaymentIcon src={JCB} />}
        {options.includes("stripe-giropay") && <PaymentIcon src={Giropay} />}
        {options.includes("stripe-ideal") && <PaymentIcon src={Ideal} />}
      </PaymentMethods>
    </Container>
  )
}

export default PaymentOptions

export default cart => {
  const displayItems = {}
  for (const i of cart.items) {
    if (i.metadata && i.metadata.bundle_id) {
      if (displayItems[i.metadata.bundle_id]) {
        const current = displayItems[i.metadata.bundle_id]
        displayItems[i.metadata.bundle_id] = {
          ...current,
          ids: [...current.ids, i.id],
          description: [current.description, i.description].join(", "),
          unit_price: current.unit_price + i.quantity * i.unit_price,
        }
      } else {
        displayItems[i.metadata.bundle_id] = {
          ids: [i.id],
          is_bundle: true,
          ...i,
          unit_price: i.quantity * i.unit_price,
          title: i.metadata.bundle_name,
          quantity: 1,
        }
      }
    } else {
      if (displayItems.simple) {
        displayItems.simple.push(i)
      } else {
        displayItems.simple = [i]
      }
    }
  }

  let items = []
  for (const [key, val] of Object.entries(displayItems)) {
    if (key === "simple") {
      items = items.concat(val)
    } else {
      items.push(val)
    }
  }

  return items
}

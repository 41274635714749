import React from "react"
import styled from "@emotion/styled"
import { Flex, Text } from "theme-ui"

import { formatPrices } from "../../../utils/prices"
import Typography from "../../ui/Typography"
import { useTranslation } from "react-i18next"
import QuantityPicker from "../quantity-picker"

const ItemPrice = styled.div`
  ${(props) => (props.isCheckout ? Typography.Small : Typography.Base)};
  ${(props) => props.isCheckout && `color: ${props.theme.colors.darkest}`};
  ${(props) => !props.isCheckout && `float: right`};
  ${(props) => props.isCheckout && `color: ${props.theme.colors.darkest}`};
`

export const CartItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  min-height: 106px;
  max-height: 150px;

  margin-bottom: 0.5rem;
  &:last-of-type {
    margin-bottom: 0;
  }

  img {
    position: center;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &:not(:last-of-type) {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.dark}1A;
  }
`

export const CartItemDetails = styled.div`
  display: flex;
  justify-content: space-between;

  .item-image {
    width: 96px;
    height: 100%;
    margin-right: 1rem;
  }

  .info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .item-details-name {
    font-size: 14px;
    margin-bottom: 0.5rem;
    color: ${(props) => props.theme.colors.darkest};
  }

  .item-details-variant {
    color: ${(props) => props.theme.colors.dark};
  }
`

export const CartItemFooter = styled.div`
  ${Typography.Base};
  margin-top: 0.5rem;
  color: ${(props) => props.theme.colors.dark};
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const RemoveFromCart = styled.div`
  cursor: pointer;
  float: right;
  margin-left: 1rem;
  margin-top: -4px;
`

const CartItem = ({
  item,
  cart,
  allowEditting,
  isCheckout,
  thumbnail,
  name,
  itemPrice,
  isBundle,
  onRemoveLine,
  onUpdateQuantity,
  disableEdit,
  simplifyQuantityPicker,
}) => {
  const price = itemPrice ? formatPrices(cart, itemPrice) : ""
  const regularPrice = itemPrice
    ? formatPrices(cart, regularPrice || itemPrice)
    : ""

  const { t } = useTranslation()

  return (
    <CartItemContainer isCheckout={isCheckout}>
      <CartItemDetails isCheckout={isCheckout}>
        <img src={thumbnail} alt={name} className="item-image" />
        <Flex sx={{ flexDirection: "column", flex: "1" }}>
          <Flex className="item-details-name">
            <Text
              sx={{
                WebkitLineClamp: 2,
                display: "-webkit-box",
                "-webkit-box-orient": "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                flex: "1",
                paddingRight: "8px",
              }}
            >
              {name}
            </Text>
            <Text>{price}</Text>
          </Flex>
          <Flex sx={{ flexDirection: "column", flex: "1" }}>
            {item.variant?.product?.subtitle && (
              <Flex sx={{ fontSize: "13px" }} className="item-details-variant">
                <Text sx={{ flex: "40px 0", color: "brownGrey" }} mr={1}>
                  {t("color")}:
                </Text>{" "}
                {item.variant.product.subtitle}
              </Flex>
            )}
            {!item.is_giftcard && (
              <Flex sx={{ fontSize: "13px" }} className="item-details-variant">
                <Text sx={{ flex: "40px 0", color: "brownGrey" }} mr={1}>
                  {t("size")}:
                </Text>{" "}
                {item.variant.title}
              </Flex>
            )}
          </Flex>
          {simplifyQuantityPicker ? (
            <Flex sx={{ fontSize: "13px" }} className="item-details-variant">
              <Text sx={{ color: "brownGrey" }} mr={1}>
                {t("quantity")}:
              </Text>{" "}
              {item.quantity}
            </Flex>
          ) : (
            <QuantityPicker
              quantity={item.quantity}
              onQuantityChanged={(quantity) =>
                onUpdateQuantity(item.id, quantity)
              }
              minQuantity={0}
              disabled={disableEdit}
            />
          )}
        </Flex>
        {onRemoveLine && (
          <RemoveFromCart
            onClick={() =>
              onRemoveLine({
                id: item.id,
                variant: item.variant,
                quantity: item.quantity,
                unit_price: itemPrice,
              })
            }
          >
            &#215;
          </RemoveFromCart>
        )}
      </CartItemDetails>
      {!isCheckout && (
        <CartItemFooter>
          <div>
            {!isBundle && allowEditting && (
              <QuantityPicker
                visible
                small
                quantity={item.quantity}
                onQuantityChanged={(quantity) =>
                  onUpdateQuantity(item.id, quantity)
                }
                disabled={disableEdit}
              />
            )}
          </div>
          <ItemPrice isCheckout={isCheckout}>
            {!isBundle && !allowEditting ? `${item.quantity} x ` : ``}
            {regularPrice !== price ? (
              <>
                <strike>{regularPrice}</strike> {price}
              </>
            ) : (
              <>{price}</>
            )}
          </ItemPrice>
        </CartItemFooter>
      )}
    </CartItemContainer>
  )
}

CartItem.defaultProps = {
  isBundle: false,
}

export default CartItem
